import React from 'react'
import '../Dashboard.scss'
import Sidebar from '../Sidebar Section/Sidebar'
import AddBookName from './Listing Section/AddBookName'

const BodyTwoBookName = () => {
  return (
    <div>
      <div className='body'>
        <div className='container'>
          <Sidebar />
          <AddBookName  />
        </div>
      </div>
    </div>
  )
}

export default BodyTwoBookName
