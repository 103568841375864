import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const UpdateUser = () => {
  const { id } = useParams();
    const [userData, setUserData] = useState(null);
    const [formData, setFormData] = useState({
        email: '',
        phone_number: '',  // Use the correct field name
        username: '',
        password: ''
    });
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`http://localhost:8080/api/v1/audioBook/get-usersBook/${id}`)
            .then(res => {
                if (res.data.status) {
                    setUserData(res.data.data);
                    setFormData({
                        email: res.data.data.email,
                        phone_number: res.data.data.phone_number,  // Use the correct field name
                        username: res.data.data.username,
                        password: res.data.data.password
                    });
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => console.log(err));
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put(`http://localhost:8080/api/v1/audioBook/update-userBook/${id}`, formData)  // Send the id as a URL parameter
            .then(res => {
                if (res.data.status) {
                    navigate('/get-bookuser');
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => console.log(err));
    };

  return (
    <div>
            <h2>Update Admin</h2>
            {userData && (
                <div className="card">
                    <h3>User Information</h3>
                    <p><strong>Username:</strong> {userData.username}</p>
                    <p><strong>Email:</strong> {userData.email}</p>
                    <p><strong>Phone:</strong> {userData.phone_number}</p>
                    <p><strong>Password:</strong> {userData.password}</p>
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Username</label>
                    <input 
                        type="text" 
                        name="username" 
                        placeholder="Enter new username" 
                        value={formData.username} 
                        onChange={handleChange} 
                    />
                </div>
                <div>
                    <label>Email</label>
                    <input 
                        type="email" 
                        name="email" 
                        placeholder="Enter new email" 
                        value={formData.email} 
                        onChange={handleChange} 
                    />
                </div>
                <div>
                    <label>Phone</label>
                    <input 
                        type="text" 
                        name="phone_number"  // Use the correct field name
                        placeholder="Enter new phone number" 
                        value={formData.phone_number} 
                        onChange={handleChange} 
                    />
                </div>
                <div>
                    <label>Password</label>
                    <input 
                        type="password" 
                        name="password" 
                        placeholder="Enter new password" 
                        value={formData.password} 
                        onChange={handleChange} 
                    />
                </div>
                <button type="submit">Update</button>
            </form>
        </div>
  )
}

export default UpdateUser
