import React from 'react'
import '../../Dashboard.scss'

const Activity = () => {
  return (
    <div>
      
    </div>
  )
}

export default Activity
