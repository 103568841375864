import React from 'react'
import '../Dashboard.scss'
import BodyTwo from '../Body Section/BodyTwo'
import Sidebar from '../Sidebar Section/Sidebar'

const AdminAdd = () => {
  return (
    <div>
            <div className='body'>
                <div className='container'>
                    <Sidebar />
                    <BodyTwo />
                </div>
            </div>  
            
    </div>
  )
}

export default AdminAdd
