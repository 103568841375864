import React from 'react';
import Body from '../Body Section/Body';
import '../Dashboard.scss'
import Sidebar from '../Sidebar Section/Sidebar'
import BodyOneBook from '../Body Section/BodyOneBook';

const GetBooks = () => {
  return (
    <div>
            <div className='body'>
                <div className='container'>
                    <Sidebar />
                    <BodyOneBook />
                </div>
            </div>  
        </div>
  )
}

export default GetBooks
