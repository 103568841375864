import React from 'react'
import Activity from './Activity Section/Activity'
import Top from './Top Section/Top'
import '../Dashboard.scss'
import ListingBook from './Listing Section/ListingBook'

const BodyOneBook = () => {
  return (
    <div className='mainContent'>
      <Top />
      <div className="bottom flex">
        <ListingBook />
        <Activity />
      </div>
    </div>
  )
}

export default BodyOneBook
