import React from 'react'
import AddAdmin from './Listing Section/AddAdmin'
import Activity from './Activity Section/Activity'

const BodyTwo = () => {
  return (
    <div>
      <AddAdmin  />
      <Activity />
    </div>
  )
}

export default BodyTwo
