import React from 'react'
import Activity from './Activity Section/Activity'
import Top from './Top Section/Top'
import '../Dashboard.scss'
import Listing from './Listing Section/Listing'

const BodyOne = () => {
  return (
    <div className='mainContent'>
      <Top />
      <div className="bottom flex">
        <Listing />
        <Activity />
      </div>
    </div>
  )
}

export default BodyOne
