import React from 'react'
import '../../Dashboard.scss'
import { BiSearchAlt } from "react-icons/bi";
import { TbMessageCircleHeart } from "react-icons/tb";
import { IoNotificationsOutline } from "react-icons/io5";
import profile_pic from '../../../LoginAssets/profile_image.png'
import video from '../../../LoginAssets/video.mp4'

const Top = () => {
  return (
    <div>
      <div className="topSection">
        <div className="headerSection flex">
          <div className="title">
            <h1>Welcome to AudioBook.</h1>
            <p>Hello Duncan, welcome back!</p>
          </div>

          <div className="searchBar flex">
            <input type="text" placeholder='Search Dashboard' />
            <BiSearchAlt className='icon' />
          </div>

          <div className="adminDiv flex">
            <TbMessageCircleHeart className='icon' />
            <IoNotificationsOutline className='icon' />
            <div className="adminImage">
              <img src={profile_pic} alt="Profile Image" />
            </div>
          </div>
        </div>
      </div>
      <div className="cardSection flex">
        <div className="rightCard flex">
          <h1>Create and Sell awesome products</h1><br />
         

          {/*<div className="buttons">
            <button className="btn">Explore more</button>
            <button className="btn transparent">Top Books</button>
          </div>

          {/*<div className="videoDiv">
            <video src={video} autoPlay loop muted></video>
          </div>*/}
        </div>
        
      </div>
    </div>
  )
}

export default Top
