import React from 'react'
import Activity from './Activity Section/Activity'
import AddBookCategory from './Listing Section/AddBookCategory'
import '../Dashboard.scss'
import Sidebar from '../Sidebar Section/Sidebar'

const BodyTwoBookCategory = () => {
  return (
    <div>
      <div className='body'>
        <div className='container'>
          <Sidebar />
          <AddBookCategory  />
        </div>
      </div>
    </div>
  )
}

export default BodyTwoBookCategory
