import React from 'react';
import Body from '../Body Section/Body';
import '../Dashboard.scss'
import Sidebar from '../Sidebar Section/Sidebar'
import BodyThree from '../Body Section/BodyThree';

const GetBookUsers = () => {
  return (
    <div>
            <div className='body'>
                <div className='container'>
                    <Sidebar />
                    <BodyThree />
                </div>
            </div>  
            <Body />
        </div>
  )
}

export default GetBookUsers
