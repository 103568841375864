// src/components/Listing/Listing.jsx
import React, { useEffect, useState } from 'react';
import styles from './listing.module.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Listing = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('http://localhost:8080/api/v1/audioBook/get-users')
            .then(res => setUsers(res.data.data))
            .catch(err => console.log(err));
    }, []);

    const handleDelete = (id) => {
        axios.delete(`http://localhost:8080/api/v1/audioBook/delete-user/${id}`)
            .then(res => {
                if (res.data.status) {
                    setUsers(users.filter(user => user.id !== id));
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => console.log(err));
    };

    const handleUpdate = (id) => {
        navigate(`/update-admin/${id}`);
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Admin Users</h1>
            <a href="/admin-add" className={styles.addLink}>Add Admin User</a>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Password</th>
                        <th>Phone Number</th>
                        <th>Update</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td>{user.password}</td>
                            <td>{user.phone_number}</td>
                            <td><button className={`${styles.button} ${styles.updateButton}`} onClick={() => handleUpdate(user.id)}>Update</button></td>
                            <td><button className={`${styles.button} ${styles.deleteButton}`} onClick={() => handleDelete(user.id)}>Delete</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Listing;
