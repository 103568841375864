import React, { useState } from 'react'
import '../../App.scss'
import video from '../LoginAssets/video.mp4'
import logo from '../LoginAssets/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { FaUserShield } from "react-icons/fa6";
import { BsFillShieldLockFill } from "react-icons/bs";
import { AiOutlineSwapRight } from "react-icons/ai";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import Axios from 'axios';

const Register = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const createUser = () => {
    Axios.post('http://localhost:8080/api/v1/audioBook/register', {
      Email:email,
      Phone: phone,
      Username:username,
      Password:password
    })
    .then(()=>{
      console.log('User created succesfully!');
      navigate('/');
    })
    .catch((error)=>{
      console.log('Cannot create user!', error)
    });
  }
  return (
    <div className='registerPage flex'>
      <div className="container flex">
        <div className="videoDiv">
          <video src={video} autoPlay loop muted></video>
          <div className="textDiv">
            <h2 className="title">Podcast App Admin Dashboard</h2>
            <p>Learn as you listen</p>
          </div>
          <div className="footerDiv flex">
            <span className="text">Have an account?</span>
            <Link to={'/'}><button className='btn'>Log In</button></Link>
          </div>
        </div>
        <div className="formDiv flex">
          <div className="registerDiv">
             <img src={logo} alt="Logo Image" />
             <h3>Let Us Know You!</h3>
          </div>
          <form action="" className='dorm grid'>
            <div className="inputDiv">
              <label htmlFor="email">Email</label>
              <div className="inputRegister flex">
                <MdOutlineMarkEmailRead  className='icon'/>
                <input type="email" id='email' placeholder='Enter EmailAddress' onChange={(e)=>setEmail(e.target.value)} required/>
              </div>
            </div>
            <div className="inputDiv">
              <label htmlFor="phone_number">Phone Number</label>
              <div className="inputRegister flex">
                <MdOutlineMarkEmailRead  className='icon'/>
                <input type="text" id='phone_no' placeholder='Enter Phone Number' onChange={(e)=>setPhone(e.target.value)} required/>
              </div>
            </div>
            <div className="inputDiv">
              <label htmlFor="username">UserName</label>
              <div className="inputRegister flex">
                <FaUserShield className='icon'/>
                <input type="text" id='username' placeholder='Enter Username' onChange={(e)=>setUsername(e.target.value)} required/>
              </div>
            </div>
            <div className="inputDiv">
              <label htmlFor="password">Password</label>
              <div className="inputRegister flex">
                <BsFillShieldLockFill className='icon'/>
                <input type="password" id='password' placeholder='Enter Password' onChange={(e)=>setPassword(e.target.value)} required/>
              </div>
            </div>
            <button type='submit' className='btn flex' onClick={createUser}>
              <span>Register </span>
              <AiOutlineSwapRight className='icon'/>
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Register;
