import React from 'react'
import '../Dashboard.scss'
import logo from '../../LoginAssets/logo.png'
import { IoMdSpeedometer } from "react-icons/io";
import { MdDeliveryDining } from "react-icons/md";
import { SiAudiomack } from "react-icons/si";
import { MdPayments } from "react-icons/md";
import { BiSolidUserAccount } from "react-icons/bi";
import { SiSoundcharts } from "react-icons/si";
import { BiTrendingUp } from "react-icons/bi";
import { BsCreditCard2FrontFill } from "react-icons/bs";
import { IoIosContacts } from "react-icons/io";
import { BsQuestionCircleFill } from "react-icons/bs";

const Sidebar = () => {
  return (
    <div className='sideBar grid'>

      <div className="logoDiv flex">
        <img src={logo} alt="Logo Image" />
        <h2>AudioBook.</h2>
      </div>

      <div className="menuDiv">
        <h3 className="divTitle">
          QUICK MENU
        </h3>

        <ul className="menuLists grid">
          <li className="listItem">
            <a href="/get-user" className="menuLink flex">
              <IoMdSpeedometer className='icon'/>
              <span className="smallText">Get Admin Users</span>
            </a>
          </li>
        </ul>

        <ul className="menuLists grid">
          <li className="listItem">
            <a href="/get-bookuser" className="menuLink flex">
              <MdDeliveryDining className='icon'/>
              <span className="smallText">Book Users</span>
            </a>
          </li>
        </ul>

        <ul className="menuLists grid">
          <li className="listItem">
            <a href="/get-book" className="menuLink flex">
              <SiAudiomack className='icon'/>
              <span className="smallText">AudioBooks</span>
            </a>
          </li>
        </ul>

        <ul className="menuLists grid">
          <li className="listItem">
            <a href="#" className="menuLink flex">
              <BiSolidUserAccount className='icon'/>
              <span className="smallText">Admin Accounts</span>
            </a>
          </li>
        </ul>

      </div>

      <div className="settingsDiv">
        <h3 className="divTitle">
          SETTINGS
        </h3>

        <ul className="menuLists grid">
          <li className="listItem">
            <a href="#" className="menuLink flex">
              <SiSoundcharts className='icon'/>
              <span className="smallText">Settings</span>
            </a>
          </li>
        </ul>

        <ul className="menuLists grid">
          <li className="listItem">
            <a href="#" className="menuLink flex">
              <BiTrendingUp className='icon'/>
              <span className="smallText">Trending</span>
            </a>
          </li>
        </ul>

        <ul className="menuLists grid">
          <li className="listItem">
            <a href="#" className="menuLink flex">
              <IoIosContacts className='icon'/>
              <span className="smallText">Contacts</span>
            </a>
          </li>
        </ul>

        <ul className="menuLists grid">
          <li className="listItem">
            <a href="#" className="menuLink flex">
              <MdPayments className='icon'/>
              <span className="smallText">Payments</span>
            </a>
          </li>
        </ul>

        <ul className="menuLists grid">
          <li className="listItem">
            <a href="#" className="menuLink flex">
              <BsCreditCard2FrontFill className='icon'/>
              <span className="smallText">Billing</span>
            </a>
          </li>
        </ul>

      </div>

      <div className="sideBarCard">
        <BsQuestionCircleFill className='icon'/>
        <div className="cardContent">
          <div className="circle1"></div>
          <div className="circle2"></div>

          <h3>Help Center</h3>
          <p>Having trouble with AudioBook, please contact for more questions.</p>
          <button className="btn">Go to help Center</button>
        </div>
      </div>

    </div>
  )
}

export default Sidebar
