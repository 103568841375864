import React from 'react'
import '../Dashboard.scss'
import BodyThree from '../Body Section/BodyThree'
import Sidebar from '../Sidebar Section/Sidebar'

const UserAdd = () => {
  return (
    <div>
            <div className='body'>
                <div className='container'>
                    <Sidebar />
                    <BodyThree />
                </div>
            </div>  
            
    </div>
  )
}

export default UserAdd
