import React from 'react'
import Activity from './Activity Section/Activity'
import Top from './Top Section/Top'
import UserAdd from '../Users/UserAdd'

const BodyThree = () => {
  return (
    <div className='mainContent'>
      <Top />
      <div className="bottom flex">
        <UserAdd />
        <Activity />
      </div>
    </div>
  )
}

export default BodyThree
