import React from 'react'
import '../Dashboard.scss'
import Sidebar from '../Sidebar Section/Sidebar'
import BodyTwoBookName from '../Body Section/BodyTwoBookName'

const BookNameAdd = () => {
  return (
    <div>
        <div className='body'>
            <div className='container'>
                <Sidebar />
                <BodyTwoBookName />
            </div>
        </div>  
            
    </div>
  )
}

export default BookNameAdd
