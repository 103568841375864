import React from 'react'
import Sidebar from '../../Sidebar Section/Sidebar'
import BodyOneBook from '../BodyOneBook'
import Body from '../Body'
import '../../Dashboard.scss'
import RegisterBook from '../../../Register/RegisterBook'

const AddBook = () => {
  return (
    <div>
    <div className='body'>
        <div className='container'>
            <Sidebar />
            <RegisterBook />
        </div>
    </div>  
    <Body />
</div>
  )
}

export default AddBook
