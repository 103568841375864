import React from 'react';
import Body from '../Body Section/Body';
import '../Dashboard.scss'
import Sidebar from '../Sidebar Section/Sidebar'
import BodyOne from '../Body Section/BodyOne';

const GetUsers = () => {

    return (
        <div>
            <div className='body'>
                <div className='container'>
                    <Sidebar />
                    <BodyOne />
                </div>
            </div>  
        </div>
    );
}

export default GetUsers;
