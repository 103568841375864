import React from 'react'
import Sidebar from './Sidebar Section/Sidebar'
import Body from './Body Section/Body'
import './Dashboard.scss'

const Dashboard = () => {
  return (
    <div className='body'>
        <div className='container'>
          <Sidebar />
          <Body />
      </div>
    </div>
  )
}

export default Dashboard;
