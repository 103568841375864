import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './updateadmin.module.css';

const UpdateBookName = () => {
    const { id } = useParams();
    const [userData, setUserData] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
    });
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`http://localhost:8080/api/v1/audioBook/get-book-name-id/${id}`)
            .then(res => {
                if (res.data.status) {
                    setUserData(res.data.data);
                    setFormData({
                        name: res.data.data.name,
                    });
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => console.log(err));
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put(`http://localhost:8080/api/v1/audioBook/update-book-name/${id}`, formData)  // Send the id as a URL parameter
            .then(res => {
                if (res.data.status) {
                    navigate('/get-book-name');
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => console.log(err));
    };
  return (
    <div className={styles.container}>
            <h2>Update Book Name</h2>
            {userData && (
                <div className={styles.card}>
                    <h3>User Information</h3>
                    <p><strong>Book Name:</strong> {userData.name}</p>
                </div>
            )}
            <form className={styles.form} onSubmit={handleSubmit}>
                <div>
                    <label>Book Category</label>
                    <input 
                        type="text" 
                        name="name" 
                        placeholder="Enter new Book Name" 
                        value={formData.name} 
                        onChange={handleChange} 
                    />
                </div>
                <button className={`${styles.button} ${styles.updateButton}`} type="submit">Update</button>
            </form>
        </div>
  )
}

export default UpdateBookName
