import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PaymentList = () => {
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const fetchPayments = async () => {
      const response = await axios.get('/api/payments');
      setPayments(response.data);
    };

    fetchPayments();
  }, []);

  return (
    <div>
      <h1>Payments</h1>
      <ul>
        {payments.map(payment => (
          <li key={payment.id}>
            {payment.id} - {payment.amount} - {payment.status}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PaymentList;
