import React from 'react'
import Activity from './Activity Section/Activity'
import Top from './Top Section/Top'
import '../Dashboard.scss'
import ListingBookCategory from './Listing Section/ListingBookCategory'

const BodyOneBookCategory = () => {
  return (
    <div className='mainContent'>
      <Top />
      <div className="bottom flex">
        <ListingBookCategory />
        <Activity />
      </div>
    </div>
  )
}

export default BodyOneBookCategory
