import React, { useEffect, useState } from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import axios from 'axios';

const PayPalPayment = () => {
  const [orderID, setOrderID] = useState(null);

  const createOrder = async () => {
    const response = await axios.post('/api/payments/create-payment');
    setOrderID(response.data.id);
    return response.data.id;
  };

  const onApprove = async (data) => {
    const response = await axios.post('/api/payments/capture-payment', {
      orderID: data.orderID,
    });

    if (response.data.status === 'COMPLETED') {
      alert('Payment successful!');
    } else {
      alert('Payment failed.');
    }
  };

  return (
    <PayPalScriptProvider options={{ 'client-id': "ASC7ZmUFz8odBaBwt2qxACOY6I_oARCSrRSf_1411_rf5G5T1ImJos56A_NAG-ZhYL6NSfp9aQVGhTmj"}}>
      <div>
        <h1>PayPal Payment</h1>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
        />
      </div>
    </PayPalScriptProvider>
  );
};

export default PayPalPayment;
