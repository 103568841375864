import React, { useState } from 'react'
import video from '../../../LoginAssets/video.mp4'
import logo from '../../../LoginAssets/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineSwapRight } from "react-icons/ai";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import Axios from 'axios';

const AddBookCategory = () => {
    const [name, setEmail] = useState('');
    const navigate = useNavigate();

    const createUser = (e) => {
      e.preventDefault();
      Axios.post('http://localhost:8080/api/v1/audioBook/book-register-category', {
        Name:name,
      })
      .then(()=>{
        console.log('Book Category created succesfully!');
        navigate('/get-book-category');
      })
      .catch((error)=>{
        console.log('Cannot create book category!', error)
      });
    }
  return (
    <div className='registerPage flex'>
      <div className="container flex">
        <div className="videoDiv">
          <video src={video} autoPlay loop muted></video>
          <div className="textDiv">
            <h2 className="title">Podcast App Admin Add</h2>
          </div>
          <div className="footerDiv flex">
            <span className="text">Learn as you listen</span>
          </div>
    </div>
        <div className="formDiv flex">
          <div className="registerDiv">
             <img src={logo} alt="Logo Image" />
             <h3>Let Us Know You!</h3>
          </div>
          <form action="" className='dorm grid'>
            <div className="inputDiv">
              <label htmlFor="name">Book Category</label>
              <div className="inputRegister flex">
                <MdOutlineMarkEmailRead  className='icon'/>
                <input type="text" id='name' placeholder='Enter Book Category' onChange={(e)=>setEmail(e.target.value)} required/>
              </div>
            </div>
            <button type='submit' className='btn flex' onClick={createUser}>
              <span>Register </span>
              <AiOutlineSwapRight className='icon'/>
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddBookCategory
