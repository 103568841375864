import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.scss';
import video from '../LoginAssets/video.mp4';
import logo from '../LoginAssets/logo.png';
import { Link } from 'react-router-dom';
import { FaUserShield } from "react-icons/fa6";
import { BsFillShieldLockFill } from "react-icons/bs";
import { AiOutlineSwapRight } from "react-icons/ai";
import Axios from 'axios';

const Login = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate('/dashboard');
  }
  return (
    <div className='loginPage flex'>
      <div className="container flex">
        <div className="videoDiv">
          <video src={video} autoPlay loop muted></video>
          <div className="textDiv">
            <h2 className="title">Podcast App Admin Dashboard</h2>
            <p>Learn as you listen</p>
          </div>
          <div className="footerDiv flex">
            <span className="text">Don't have an account?</span>
            <Link to={'/register'}><button className='btn'>Sign Up</button></Link>
          </div>
        </div>
        <div className="formDiv flex">
          <div className="headerDiv">
             <img src={logo} alt="Logo Image" />
             <h3>Welcome Back!</h3>
          </div>
          <form action="" className='form grid' onSubmit={handleSubmit}>
            <span className='message'>Login goes here</span>
            <div className="inputDiv">
              <label htmlFor="username">UserName</label>
              <div className="input flex">
                <FaUserShield className='icon'/>
                <input type="text" id='username' placeholder='Enter Username'  />
              </div>
            </div>
            <div className="inputDiv">
              <label htmlFor="password">Password</label>
              <div className="input flex">
                <BsFillShieldLockFill className='icon'/>
                <input type="password" id='password' placeholder='Enter Password'  />
              </div>
            </div>
            <button type='submit' className='btn flex' >
              <span>Login </span>
              <AiOutlineSwapRight className='icon'/>
            </button>
            <span className='forgotPassword'>
              Forgot your password <a href="">Click Here.</a>
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
