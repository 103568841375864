import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const PaymentDetails = () => {
  const { id } = useParams();
  const [payment, setPayment] = useState(null);

  useEffect(() => {
    const fetchPayment = async () => {
      const response = await axios.get(`/api/payments/${id}`);
      setPayment(response.data);
    };

    fetchPayment();
  }, [id]);

  if (!payment) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Payment Details</h1>
      <p>ID: {payment.id}</p>
      <p>Amount: {payment.amount}</p>
      <p>Status: {payment.status}</p>
    </div>
  );
};

export default PaymentDetails;
