import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import RegisterBook from './components/Register/RegisterBook';
import GetUsers from './components/Dashboard/Users/GetUsers';
import GetBooks from './components/Dashboard/Users/GetBooks';
import GetBookUsers from './components/Dashboard/Users/GetBookUsers';
import UpdateAdmin from './components/Dashboard/Body Section/Listing Section/UpdateAdmin';
import UpdateBook from './components/Dashboard/Body Section/Listing Section/UpdateBook';
import UpdateUser from './components/Dashboard/Body Section/Listing Section/UpdateUser';
import AddAdmin from './components/Dashboard/Body Section/Listing Section/AddAdmin';
import AddBook from './components/Dashboard/Body Section/Listing Section/AddBook';
import AddUser from './components/Dashboard/Body Section/Listing Section/AddUser';
import AdminAdd from './components/Dashboard/Users/AdminAdd';
import BookAdd from './components/Dashboard/Users/BookAdd';
import UserAdd from './components/Dashboard/Users/UserAdd';
import RegisterBookCategory from './components/Register/RegisterBookCategory';
import GetBookCategory from './components/Dashboard/Users/GetBookCategory';
import UpdateBookCategory from './components/Dashboard/Body Section/Listing Section/UpdateBookCategory';
import BookCategoryAdd from './components/Dashboard/Users/BookCategoryAdd';
import RegisterBookName from './components/Register/RegisterBookName';
import GetBookName from './components/Dashboard/Users/GetBookName';
import UpdateBookName from './components/Dashboard/Body Section/Listing Section/UpdateBookName';
import BookNameAdd from './components/Dashboard/Users/BookNameAdd';
import PayPalPayment from './components/PayPal/PayPalPayment';
import PaymentList from './components/PayPal/PaymentList';
import PaymentDetails from './components/PayPal/PaymentDetails';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<div><Login /></div>} />
        <Route path='/dashboard' element={<div><Dashboard /></div>} />
        <Route path='/register' element={<div><Register /></div>} />
        <Route path='/register-book-category' element={<div><RegisterBookCategory /></div>} />
        <Route path='/register-book-name' element={<div><RegisterBookName /></div>} />
        <Route path='/register-book' element={<div><RegisterBook /></div>} />
        <Route path='/get-user' element={<div><GetUsers /></div>} />
        <Route path='/get-book-category' element={<div><GetBookCategory /></div>} />
        <Route path='/get-book-name' element={<div><GetBookName /></div>} />
        <Route path='/get-book' element={<div><GetBooks /></div>} />
        <Route path='/get-bookuser' element={<div><GetBookUsers /></div>} />
        <Route path='/update-admin/:id' element={<div><UpdateAdmin /></div>} />
        <Route path='/update-book-category/:id' element={<div><UpdateBookCategory /></div>} />
        <Route path='/update-book-name/:id' element={<div><UpdateBookName /></div>} />
        <Route path='/update-book/:id' element={<div><UpdateBook /></div>} />
        <Route path='/update-user/:id' element={<div><UpdateUser /></div>} />
        <Route path='/add-admin' element={<div><AddAdmin /></div>} />
        <Route path='/add-book' element={<div><AddBook /></div>} />
        <Route path='/add-user' element={<div><AddUser /></div>} />
        <Route path='/admin-add' element={<div><AdminAdd /></div>} />
        <Route path='/book-category-add' element={<div><BookCategoryAdd /></div>} />
        <Route path='/book-name-add' element={<div><BookNameAdd /></div>} />
        <Route path='/book-add' element={<div><BookAdd /></div>} />
        <Route path='/user-add' element={<div><UserAdd /></div>} />
        <Route path='/paypal-payment' element={<PayPalPayment />} />
        <Route path='/payments' element={<PaymentList />} />
        <Route path='/payments/:id' element={<PaymentDetails />} />
      </Routes>
    </div>
  );
}

export default App;
