import React from 'react';
import '../Dashboard.scss'
import Sidebar from '../Sidebar Section/Sidebar'
import BodyOneBookCategory from '../Body Section/BodyOneBookCategory';
import BodyOneBookName from '../Body Section/BodyOneBookName';

const GetBookName = () => {
  return (
    <div>
        <div className='body'>
            <div className='container'>
                <Sidebar />
                <BodyOneBookName />
            </div>
        </div>  
    </div>
  )
}

export default GetBookName
