// src/components/ListingBook/ListingBook.jsx
import React, { useEffect, useState } from 'react';
import styles from './listingbook.module.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ListingBook = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('http://localhost:8080/api/v1/audioBook/get-book')
            .then(res => setUsers(res.data.data))
            .catch(err => console.log(err));
    }, []);

    const handleDelete = (id) => {
        axios.delete(`http://localhost:8080/api/v1/audioBook/delete-book/${id}`)
            .then(res => {
                if (res.data.status) {
                    setUsers(users.filter(user => user.id !== id));
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => console.log(err));
    };

    const handleUpdate = (id) => {
        navigate(`/update-book/${id}`);
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Chapter Audio's</h1>
            <div>
                <a href="/add-book" className={styles.addLink}>Add A Book Audio</a>
                <p>    </p>
                <a href="get-book-name" className={styles.addLink}>Add Book Name</a>
                <p>    </p>
                <a href="/get-book-category" className={styles.addLink}>Add Book Category</a>
            </div>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Book Name</th>
                        <th>Author</th>
                        <th>File</th>
                        <th>Pay Status/Per Chapter</th>
                        <th>Update</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.book_name}</td>
                            <td>{user.author}</td>
                            <td>{user.file}</td>
                            <td>Ksh. {user.pay_status} /=</td>
                            <td><button className={`${styles.button} ${styles.updateButton}`} onClick={() => handleUpdate(user.id)}>Update</button></td>
                            <td><button className={`${styles.button} ${styles.deleteButton}`} onClick={() => handleDelete(user.id)}>Delete</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ListingBook;
