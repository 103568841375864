import React from 'react';
import '../Dashboard.scss'
import Sidebar from '../Sidebar Section/Sidebar'
import BodyOneBookCategory from '../Body Section/BodyOneBookCategory';

const GetBookCategory = () => {
  return (
    <div>
      <div className='body'>
          <div className='container'>
              <Sidebar />
              <BodyOneBookCategory />
          </div>
      </div>  
    </div>
  )
}

export default GetBookCategory
