import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './updateadmin.module.css';
import { MdOutlineMarkEmailRead } from 'react-icons/md';

const UpdateBook = () => {
    const { id } = useParams();
    const [userData, setUserData] = useState(null);
    const [categories, setCategories] = useState([]);
    const [bookNames, setBookNames] = useState([]);
    const [formData, setFormData] = useState({
        book_name: '',
        book_category: '',
        author: '',
        file: null, // Initialize as null since it's a file
        pay_status: ''
    });
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch book details
        axios.get(`http://localhost:8080/api/v1/audioBook/get-book-id/${id}`)
            .then(res => {
                if (res.data.status) {
                    setUserData(res.data.data);
                    setFormData({
                        book_name: res.data.data.book_name,
                        book_category: res.data.data.book_category,
                        author: res.data.data.author,
                        file: null, // Set file as null since it's a file input
                        pay_status: res.data.data.pay_status
                    });
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => console.log(err));
        
        // Fetch categories
        axios.get('http://localhost:8080/api/v1/audioBook/get-book-category')
            .then(res => {
                if (res.data.status) {
                    console.log('Categories:', res.data.data); // Debug log
                    setCategories(res.data.data); // Adjust based on API response structure
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => console.log(err));

        // Fetch book names
        axios.get('http://localhost:8080/api/v1/audioBook/get-book-name')
            .then(res => {
                if (res.data.status) {
                    console.log('Book Names:', res.data.data); // Debug log
                    setBookNames(res.data.data); // Adjust based on API response structure
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => console.log(err));
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            file: file
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Create a FormData object to handle file upload
        const updateData = new FormData();
        updateData.append('book_name', formData.book_name);
        updateData.append('book_category', formData.book_category);
        updateData.append('author', formData.author);
        updateData.append('pay_status', formData.pay_status);
        if (formData.file) {
            updateData.append('file', formData.file);
        }

        axios.put(`http://localhost:8080/api/v1/audioBook/update-book/${id}`, updateData)
            .then(res => {
                if (res.data.status) {
                    navigate('/get-book');
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => console.log(err));
    };

    return (
        <div className={styles.container}>
            <h2>Update Book</h2>
            {userData && (
                <div className={styles.card}>
                    <h3>User Information</h3>
                    <p><strong>Book Name:</strong> {userData.book_name}</p>
                    <p><strong>Author:</strong> {userData.author}</p>
                    <p><strong>File:</strong> {userData.file}</p>
                    <p><strong>Pay Status: Ksh. </strong> {userData.pay_status}</p>
                </div>
            )}
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className="inputDiv">
                    <label htmlFor="book_category">Book Category</label>
                    <div className="inputRegister flex">
                        <MdOutlineMarkEmailRead className='icon' />
                        <select id="book_category" name="book_category" value={formData.book_category} onChange={handleChange} required>
                            <option value="" disabled>Select a book category</option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.book_category}>
                                    {category.book_category}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="inputDiv">
                    <label htmlFor="book_name">Book Name</label>
                    <div className="inputRegister flex">
                        <MdOutlineMarkEmailRead className='icon' />
                        <select id="book_name" name="book_name" value={formData.book_name} onChange={handleChange} required>
                            <option value="" disabled>Select a book name</option>
                            {bookNames.map((book) => (
                                <option key={book.id} value={book.book_name}>
                                    {book.book_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <label>Author</label>
                    <input 
                        type="text" 
                        name="author" 
                        placeholder="Enter new author" 
                        value={formData.author} 
                        onChange={handleChange} 
                    />
                </div>
                <div>
                    <label>Amount Paid Per Chapter</label>
                    <input 
                        type="text" 
                        name="pay_status" 
                        placeholder="Enter new pay status" 
                        value={formData.pay_status} 
                        onChange={handleChange} 
                    />
                </div>
                <div>
                    <label>Chapter Audio</label>
                    <input 
                        type="file" 
                        id="chapter_audio"
                        name="file"
                        onChange={handleFileChange} 
                    />
                </div>
                <button className={`${styles.button} ${styles.updateButton}`} type="submit">Update</button>
            </form>
        </div>
    );
};

export default UpdateBook;
